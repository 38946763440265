<script setup>
import { ref, getCurrentInstance, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import LeaderboardRow from '../components/leaderboardRow.vue'
import CarDialog from '../pages/CarDialogHash.vue'
import { ServerStore } from "../pinia/Server.js"
import { DriverStore } from '../pinia/Driver.js'

const Driver = DriverStore()
const route = useRoute()
const router = useRouter()
const { proxy } = getCurrentInstance()

const Server = ServerStore()
const race = ref([])
const raceDispay = ref(false)
const rows = ref([])
const page = ref(1)
const nbHits = ref(0)
const paginationdisplay = ref(false)
const perPage = ref(0)
const cars = ref([])
const classes = ref([])
const chips_class = ref(0)
const dialog = ref(false)
const hash = ref('')
const hashrace = ref('')
const carfiltre = ref('')
const ordertime = ref('asc')
const showPU = ref(true)
const showTAC = ref(false)

function filterTime() {
    ordertime.value = ordertime.value === "asc" ? "desc" : "asc"
    page.value = 1
    rows.value.reverse()
}

function dialogData(e, f) {
    hash.value = e
    hashrace.value = f
    dialog.value = true
}

callRace()
callRows()

function callCars() {
    proxy.$http.post("", JSON.stringify({
        serviceName: "leaderboard",
        methodName: "GetCars",
        parameters: [route.params.id, (route.params.pu === "nopu" ? 1 : 0), route.params.class]
    })).then(response => {
        cars.value = response.data
    }).catch(function (error) {})
}

async function callRace() {
    var res = await proxy.$api({
        serviceName: "ranking",
        methodName: "GetRace",
        parameters: [route.params.id]
    })
    race.value = res
    raceDispay.value = true
    document.title = res.name + " - NightRiderz"
    GetServerInfo()
}

async function GetServerInfo() {
    var classs = await proxy.$api({
        serviceName: "server",
        methodName: "moonset"
    })
    Server.classes = classs.classes
    var res = ['ALL']
    classs.classes.reverse().forEach((e, i) => {
        res.push(e.name.replace('CLASS ', ''))
    })
    classes.value = res
    chips_class.value = race.value.class === "O" ? (classes.value.indexOf(route.params.class) > 0 ? classes.value.indexOf(route.params.class) : 0) : race.value.class
}

function getStartOfWeek() {
    const startOfWeek = new Date()
    const day = startOfWeek.getDay()
    const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1) // Adjust to make Monday the first day of the week
    startOfWeek.setDate(diff)
    startOfWeek.setHours(0, 0, 0, 0)
    return startOfWeek
}

function callRows(newPage = 0) {
    let parameters = [route.params.id, route.params.page, (route.params.pu === "nopu" ? 1 : 0), route.params.class, carfiltre.value, ordertime.value]

    if (showTAC.value) {
        parameters.push(race.value.carTAC)
    }

    proxy.$http.post("", JSON.stringify({
        serviceName: "leaderboard",
        methodName: "GetData",
        parameters
    })).then(response => {
        let filteredRows = response.data.event.hits
        if (showTAC.value) {
            const startOfWeek = getStartOfWeek()
            filteredRows = filteredRows.filter(row => {
                const datePlay = new Date(row.race.date)
                return datePlay >= startOfWeek
            })
        }
        rows.value = filteredRows
        perPage.value = response.data.event.request_params.per_page
        nbHits.value = response.data.event.found
        paginationdisplay.value = true

        if (carfiltre.value === '') {
            callCars()
        }
    }).catch(function (error) {
        rows.value = []
    })
}

function toggleTAC() {
    showTAC.value = !showTAC.value
    page.value = 1
    callRows(1)
}

watch(route, (newValue, oldValue) => {
    if (ordertime.value === "asc")
        callRows(1)
})

watch(page, (newValue, oldValue) => {
    window.scrollTo(0, 0)
    router.push('/leaderboard/' + route.params.id + '/' + newValue + '/' + route.params.pu + '/' + route.params.class)
})

function goback() {
    router.push('/leaderboards/all/rotation')
}

function CarFilter(e) {
    if (e) {
        carfiltre.value = e.value
    } else {
        carfiltre.value = ''
    }
    page.value = 1
    callRows(1)
}

function togglePU() {
    showPU.value = !showPU.value
    router.push(`/leaderboard/${route.params.id}/${page.value}/${showPU.value ? 'pu' : 'nopu'}/${route.params.class}`)
}

function redirectToTimeAttackLeaderboard() {
    const newId = parseInt(route.params.id) + 5000
    window.location.href = `/leaderboard/${newId}/${page.value}/nopu/${route.params.class}`
}

function redirectToClassicLeaderboard() {
    const newId = parseInt(route.params.id) - 5000
    window.location.href = `/leaderboard/${newId}/${page.value}/${showPU.value ? 'pu' : 'nopu'}/${route.params.class}`
}
</script>


<template>
<v-app-bar :elevation="2" v-if="raceDispay" style="overflow: initial;">
    <v-btn icon dark @click="goback">
        <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <img :src="'https://cdn.nightriderz.world/images/website/moonset/modes/' + race.eventModeId + '.png'" width="45">
    <v-app-bar-title>
        <div style="text-transform: uppercase;">
    {{ race.name.replace(/\[TIME-ATTACK\]/i, "").replace(/ *\([^)]*\) */g, "").trim() }}
</div>

        <div class="type">
            <div style="background: #1f2430;max-width: fit-content;padding-left: 4px; padding-right: 4px; margin-right: 4px;border-radius: 4px;display: flex;">{{ race.class == 'O' ? 'Open' : race.class }} CLASS</div>
            <div v-if="route.params.id >=5000" style="background: #1f2430;max-width: fit-content;padding-left: 4px; padding-right: 4px; margin-right: 4px;border-radius: 4px;">TIME-ATTACK MODE</div>
            <div v-if="route.params.id <=5000" style="background: #1f2430;max-width: fit-content;padding-left: 4px; padding-right: 4px; margin-right: 4px;border-radius: 4px;">CLASSIC MODE</div>
        </div>
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <div class="nav" v-if="(race.eventModeId == 4 || race.eventModeId == 9) && (route.params.id <= 5000 || route.params.id >= 6000)">
        <v-tooltip activator="parent" location="bottom">Switch to time-attack leaderboard</v-tooltip><v-btn class="button-grey" style="min-width: 32px!important;min-height: 32px!important;height:32px!important;margin-right: 10px;" @click="redirectToTimeAttackLeaderboard"><v-icon>mdi-car-clock</v-icon></v-btn>
    </div>
    <div class="nav" v-if="(race.eventModeId == 4 || race.eventModeId == 9) && (route.params.id >= 5000 && route.params.id <= 6000)">
        <v-tooltip activator="parent" location="bottom">Switch to classic leaderboard</v-tooltip><v-btn class="button-grey" style="min-width: 32px!important;min-height: 32px!important;height:32px!important;margin-right: 10px;" @click="redirectToClassicLeaderboard"><v-icon>mdi-car-clock</v-icon></v-btn>
    </div>
    <v-select v-if="!showTAC" placeholder="Choose a car to filter results" class="search" density="compact" solo :options="cars" style="height: 32px;width: 404px;" label="fullName" @update:modelValue="CarFilter" :style="(race.eventModeId != 19 && route.params.id <= 5000) ? 'margin-right:10px;':''">
        <template v-slot:option="option">
            <div class="d-flex" style="justify-content: center;align-items: center;"><span style="width: 340px;text-overflow: ellipsis;overflow: hidden;">{{ option.fullName }}</span>
                <v-spacer></v-spacer>
                <v-chip size="small">{{ number_format(option.count)}} times
                </v-chip>
            </div>
        </template>
    </v-select>
    <div class="nav" v-if="race.eventModeId != 19 && race.isTAC == 0 && route.params.id <= 5000">
        <v-tooltip activator="parent" location="bottom">Times with powerups</v-tooltip><v-btn :class="showPU ? 'button-red' : 'button-grey'" style="min-width: 32px!important;min-height: 32px!important;height:32px!important" @click="togglePU"><img src="https://cdn.nightriderz.world/images/website/icons/powerup.png" style="height: 32px;"></v-btn>
    </div>

    <div class="nav" v-if="race.eventModeId != 19 && race.isTAC == 1 && route.params.id <= 5000">
        <v-tooltip activator="parent" location="bottom">Times with powerups</v-tooltip><v-btn :class="showPU ? 'button-red' : 'button-grey'" style="min-width: 32px!important;min-height: 32px!important;height:32px!important" @click="togglePU"><img src="https://cdn.nightriderz.world/images/website/icons/powerup.png" style="height: 32px;"></v-btn>
    </div>

    <div class="nav" v-if="race.eventModeId != 19 && race.isTAC == 1">
        <v-tooltip activator="parent" location="bottom">Time Attack Challenge</v-tooltip><v-btn :class="showTAC ? 'button-red' : 'button-grey'" style="min-width: 32px!important;min-height: 32px!important;height:32px!important;margin-left: 10px;" @click="toggleTAC"><v-icon>mdi-timer</v-icon></v-btn>
    </div>

    <template v-slot:extension>

        <v-chip-group filter v-model="chips_class" v-if="race.class == 'O'">
            <v-chip label :value="index" v-for="(classe, index) in classes" :to="'/leaderboard/' + route.params.id + '/' + route.params.page + '/' + route.params.pu + (classe != 'ALL' ? '/'+classe : '') + ''">{{classe}}</v-chip>
        </v-chip-group>
        <v-chip-group filter v-model="chips_class" v-else>
            <v-chip label :value="race.class" :to="'/leaderboard/' + route.params.id + '/' + route.params.page + '/' + route.params.pu">{{race.class}}</v-chip>
        </v-chip-group>

        <v-spacer></v-spacer>

        <div class="text-center" v-if="paginationdisplay">
            <v-pagination :size="30" v-model="page" :length="nbHits / perPage % 1 === 0 ? nbHits / perPage : Math.ceil(nbHits / perPage)" :total-visible="4"></v-pagination>
        </div>

    </template>
</v-app-bar>

<CarDialog v-if="dialog" :dialog="dialog" :hash="hash" :race="hashrace" @close-dialog="dialog = false" />

<v-table theme="dark" fixed-header height="calc(100vh - 115px)" density="compact">
    <thead>
        <tr>
            <th class="text-left">RANK</th>
            <th class="text-left">DRIVER</th>
            <th>CAR</th>
            <th class="text-left">DETAILS</th>
            <th class="text-left">TIME <!--<v-icon @click="filterTime" icon="mdi-chevron-up" size="x-small"></v-icon>--></th>
            <th class="text-left" v-if="race.eventModeId == 4">BEST LAP</th>
            <th class="text-left">TOP SPEED</th>
            <th class="text-left">DATE</th>
            <th class="action" v-if="Driver.user.isAdmin == '1'">DELETE</th>
        </tr>
    </thead>
    <tbody>
        <LeaderboardRow v-for="(row, position) in rows" :key="row.race.id" :data="row" 
        :position="(position * 1 + 1) + (page * 1 == 1 ? 0 : 100 * (page * 1 - 1))"
        @dialogData="dialogData" @refresh="callRows(route.params.page)" />
    </tbody>
</v-table>
</template>


<style>


.leaderdialog .v-overlay__scrim {
    display: none;
}

.v-toolbar-title__placeholder {
    display: flex !important;
}

.v-toolbar-title__placeholder img {
    margin-right: 10px;
}

.v-toolbar-title div:nth-child(2) {
    display: flex;
    flex-direction: column;
}

.v-pagination {
    margin: 0 !important;
}

.button-red {
    background: var(--tertiary-color) !important;
    color: white;
    width: 6px !important;
}

.button-grey {
    background: var(--secondary-color) !important;
    color: white;
    width: 2px !important;
}

.v-pagination__item,
.v-pagination__first,
.v-pagination__prev,
.v-pagination__next,
.v-pagination__last {
    margin: 0 5px !important;
}
</style><style scoped>
.action {
    width: 25px;
    background: #11141c!important;
}

.search {
    width: 450px;
    background: rgb(var(--v-theme-surface));
    border-radius: 4px;
    padding-right: 3px;
}

.v-toolbar::v-deep(.v-toolbar-title__placeholder) {
    flex-direction: column;
}

.v-select::v-deep(li) {
    margin: 0;
    overflow: hidden;
}

.v-select::v-deep(v-chip) {
    margin: 0;
    padding: 0;
}

.v-select::v-deep(button) {
    background: transparent;
}

.v-main {
    --v-layout-top: initial !important;
    padding-top: 115px !important;
}

html {
    overflow: hidden !important;
}

.v-table::v-deep(th) {
    background: var(--primary-color) !important;
}

.type {
    font-size: 12px;
    display: flex !important;
    text-transform: uppercase;
    flex-direction: row!important;
}

nav {
    font-size: 14px;
}

.col {
    flex: 1;
}

.size {
    width: 150px;
}

.title {
    display: flex;
    align-items: center;
}

.title h1 {
    margin: 0 15px;
}

.rank {
    width: 50px;
    text-align: center;
}

.router-link-active button,
.v-pagination::v-deep(.v-pagination__item--is-active button),
.v-pagination::v-deep(button:hover),
.v-chip--selected,
.v-chip:hover {
    color: white !important;
    background: var(--tertiary-color);
}
</style><style>
* {
    --vs-controls-color: white;
    --vs-border-color: transparent;
    --vs-dropdown-bg: var(--primary-color);
    --vs-dropdown-color: white;
    --vs-dropdown-option-color: white;
    --vs-selected-bg: rgb(38, 107, 173);
    --vs-selected-color: #eeeeee;
    --vs-search-input-color: #eeeeee;
    --vs-dropdown-option--active-bg: var(--tertiary-color);
    --vs-dropdown-option--active-color: #eeeeee;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.select {
    background: var(--secondary-color);
    width: 250px;
    padding: 8px 10px;
    overflow: initial;
    border: none;
    height: 46px;
    border-radius: 6px;
}

.select button {
    background: transparent;
}

textarea:focus,
input:focus {
    outline: none;
}

.vs__actions {
    height: 27px;
}
</style>
