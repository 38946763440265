<script setup>
import challengeCard from '../components/challengeCard.vue';
import SKchallengeCard from '../components/skeletons/SKchallengeCard.vue';
import {
    ref,
    toRefs,
    getCurrentInstance,
    computed
} from 'vue';
import {
    useRoute,
    useRouter
} from 'vue-router';
import {
    DriverStore
} from '../pinia/Driver.js';

const props = defineProps({
    data: Object,
});
const {
    data
} = toRefs(props);
const Driver = DriverStore();
const {
    proxy
} = getCurrentInstance();
const route = useRoute();
const router = useRouter();
const items = ref([]);
const date_title = ref("");
const done = ref([]);
const dialog = ref(false);
const playerDone = ref([]);
const isPink = ref(false);
const playerDoneCount = ref([]);

// Fonction pour obtenir la date actuelle en CET (Central European Time)
function getCurrentDateCET() {
    const date = new Date();
    const formatter = new Intl.DateTimeFormat('fr-FR', {
        timeZone: 'Europe/Paris',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    const parts = formatter.formatToParts(date);
    const year = parseInt(parts.find(p => p.type === 'year').value);
    const month = parseInt(parts.find(p => p.type === 'month').value) - 1; // Mois de 0 à 11
    const day = parseInt(parts.find(p => p.type === 'day').value);
    const hour = parseInt(parts.find(p => p.type === 'hour').value);
    const minute = parseInt(parts.find(p => p.type === 'minute').value);
    const second = parseInt(parts.find(p => p.type === 'second').value);

    return new Date(year, month, day, hour, minute, second);
}

// Fonction pour obtenir le numéro de la semaine (lundi à lundi)
function getWeekNumber(d) {
    // Convertir la date en UTC pour éviter les problèmes de fuseaux horaires
    const date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // Trouver le jeudi de la semaine actuelle
    const dayOfWeek = date.getUTCDay(); // 0 (dimanche) à 6 (samedi)
    const diffToThursday = (dayOfWeek + 6) % 7 - 3; // Ajustement pour arriver au jeudi
    date.setUTCDate(date.getUTCDate() - diffToThursday);

    // Calculer le jeudi de la première semaine de l'année
    const firstThursday = new Date(Date.UTC(date.getUTCFullYear(), 0, 4));
    const diffToFirstThursday = (firstThursday.getUTCDay() + 6) % 7 - 3;
    firstThursday.setUTCDate(firstThursday.getUTCDate() - diffToFirstThursday);

    // Calculer la différence en jours et convertir en numéro de semaine
    const weekNumber = Math.ceil(((date - firstThursday) / 86400000 + 1) / 7);

    return weekNumber;
}


// Tester les fonctions
const currentDate = getCurrentDateCET();
const weekNumber = getWeekNumber(currentDate);
//console.log("Date actuelle CET :", currentDate.toISOString());
//console.log("Numéro de la semaine actuelle :", weekNumber);

const zeroPad = (num, places) => String(num).padStart(places, '0');

getDone();

function pink() {
    isPink.value = true;
    document.querySelector('.v-app-bar').style.display = "none";
    document.querySelector('.grid').style.background = "rgb(255, 0, 247)";

    document.querySelectorAll('.challenge_card').forEach(e => {
        e.style.background = "white";
        e.style.color = "black";
    });

    document.querySelectorAll('.custom').forEach(e => {
        e.style.color = "white";
    });
}

async function getChallenges(year = route.params.year, week = route.params.week) {
    !year ? year = currentDate.getFullYear() : '';
    !week ? week = weekNumber : '';

    router.push('/challenges/' + year + '/' + zeroPad(week, 2));

    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetWeek",
        parameters: [year, week]
    });
    date_title.value = res.date;
    items.value = res.loop;
}

async function getDone(year = route.params.year, week = route.params.week) {
    !year ? year = currentDate.getFullYear() : '';
    !week ? week = weekNumber : '';

    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetDone",
        parameters: [year, week]
    });

    done.value = res;
    getChallenges(year, week);
}

async function claim(id) {
    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetClaim",
        parameters: [id]
    });
    getDone();
}

async function PlayerDone(id) {
    const res = await proxy.$api({
        serviceName: "challenges",
        methodName: "GetPlayerDoneMoonset",
        parameters: [id]
    });
    playerDone.value = res.names;
    playerDoneCount.value = res.count;
    dialog.value = true;
}

function dateChange(e) {
    items.value = [];
    var value = e.target.value.split("-");
    var y = value[0];
    var w = value[1].substring(1);
    getDone(y, w);
}
</script>

<template>
<v-app-bar :elevation="2">
    <v-icon icon="mdi-trophy-award"></v-icon>
    <v-app-bar-title>Challenges</v-app-bar-title>
    <v-spacer></v-spacer>
    <template v-if="Driver.user.isAdmin == '1'">
        <v-btn variant="tonal" prepend-icon="mdi-link-variant" @click="pink">
            Screenshot mode
        </v-btn>
    </template>
    <template v-slot:extension>
        <div>
        <label>Selected week: </label>
        <input type="week" id="week" name="week" :value="route.params.year + '-W' + route.params.week" :max="currentDate.getFullYear() + '-W0' + weekNumber" style="color:white" @change="dateChange">
        </div>
    </template>
</v-app-bar>
<transition name="fade" mode="out-in">
    <div key=1 class="grid skeleton" v-if="items.length == 0">
        <SKchallengeCard v-for="i in 8"/>
    </div>
    <div key=2 class="grid" v-else>
        <challengeCard v-for="(item, index) in items" :key="item.ID" :data="item" :pink="isPink" :done="done[index]" @claim="claim" @PlayerDone="PlayerDone" />
    </div>
</transition>
<v-dialog v-model="dialog" width="300" height="500">
    <v-card>
        <v-card-text>
            <v-card style="background: #11141c;padding-left: 6px;"><h3>{{ playerDoneCount }} <span style="opacity:60%">PARTICIPANTS</span></h3></v-card>
            <v-list lines="one">
                <v-list-item v-for="item in playerDone" :key="item.name" :title="item.name" :href="`https://nightriderz.world/driver/${item.name}`" style="border-radius: 4px;background: #11141c;margin-bottom: 4px;"></v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-btn variant="tonal" block @click="dialog = false">Close</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<style scoped>
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1350px;
    margin: 0 auto;
}
</style>
